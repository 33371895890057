<template>
  <div class="h-min-content max-w-full mt-0">
    <div class="border-2 rounded-xl shadow-xl pt-6 mt-0 px-6">
      <h2 class=" text-xl xl:text-2xl 4xl:text-4xl text-blue-dark uppercase mb-4">
        <b>¡{{$store.state.session.user.first_name}}, TE DAMOS LA BIENVENIDA A LA PLATAFORMA ADMINISTRATIVA DE SMARTSUPERVISION!</b>
      </h2>
      <h2 v-if="showEnvironmentText" class="text-xl xl:text-2xl 4xl:text-4xl uppercase environment-text mb-4">
        <b>Este entorno de pruebas está diseñado para evaluar nuevas funcionalidades y realizar pruebas. Para acceder al entorno de producción, <a href="https://smart.superfinanciera.gov.co" class="environment-text underline">haga clic aquí</a></b>
      </h2>
      <div class="flex justify-center mt-0 gap-3 mb-2">
        <a
          href="https://www.superfinanciera.gov.co/"
          target="_blank"
          rel="noopener"
          class="h-full"
        >
          <img
            src="@/assets/img/SFC_nav.png"
            style="height: 45px !important;"
            class="sm:block h-full mb-4"
            alt="Potencia Vida"
          />
        </a>
        <!--<img
        src="@/assets/img/SFC_nav.png"
        style="height: 60px !important;"
            class="h-full cursor-pointer sm:hidden md:block"
            alt="Min Hacienda"
          />-->
      </div>
    </div>
    <div class="mt-3">
      <HomeList />
    </div>
  </div>
</template>

<script>
import HomeList from "../../components/Dashboard/Alerts/HomeList.vue";
import { URL_DEV_ENVIRONMENT_LIST} from '../../core/utils/constants.js';
export default {
  components: { HomeList },
  data() {
    return {
      showEnvironmentText: false
    };
  },
  created() {
    const currentUrl = window.location.origin;     
    if (URL_DEV_ENVIRONMENT_LIST.includes(currentUrl)) {
      this.showEnvironmentText = true;
    }
  }
};

</script>
<style scoped>
.environment-text {
  color: #ffa02a;
}

.environment-text:hover {
  color: #ffa02a;
}
</style>